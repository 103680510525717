import { parseStrings } from "./studentDataProcessing";

export const rowsByKeys = (rows, keys) => {
  if (keys.length === 0) return rows; //an empty array means "all"

  return rows.map(row => ({
    ...row,
    cells: row.cells.filter(cell => keys.includes(cell.key)),
  }));
};

export const colsByKeys = (columns, keys) => {
  if (keys.length === 0) return columns; //an empty array means "all"

  return columns.filter(column => keys.includes(column.key));
};

// We can abstract out more in the future to search by any key, or multiple.
export const rowsByName = (rows, name) => {
  return rows.filter(row => {
    const nameCell = row.cells.find(cell => cell.key === "name");
    return (
      nameCell && nameCell.value.toLowerCase().includes(name.toLowerCase())
    );
  });
};

// This function is used to update the rows in the DataGridProvider
// given an event to handle
export const updateStudentRows = (event, currRows) => {
  const { name, value, checked } = event.target;
  // eslint-disable-next-line no-unused-vars
  const [key, uuid, __] = name.split("_");
  const formattedKey = key.replace(/-/g, "_");

  const updatedRows = currRows.map(row => {
    if (row.uuid === uuid) {
      const updatedCells = row.cells.map(cell => {
        if (cell.key === key) {
          return { ...cell, value };
        }
        return cell;
      });

      return {
        ...row,
        [formattedKey]: parseStrings(key, checked ? checked : value),
        cells: updatedCells,
      };
    }
    return row;
  });

  return updatedRows;
};
