import PropTypes from "prop-types";
import classnames from "classnames";
import HeaderNavigationItem from "./HeaderNavigationItem";

/**
 * Stylized navigation of pages to visit while completing student information
 * @param className - any additional classes to add to the component, for ex margins
 * @param navItems: Array of HeaderNavigationItem instances
 * Each object should have the following properties (See HeaderNavigationItem for more details):
 * - text
 * - location
 * - active
 * - available
 */
export default function HeaderNavigation({ className, navItems }) {
  return (
    <nav aria-label="Student information steps">
      <ol
        className={classnames(
          className,
          "m-auto max-w-[1150px] mt-5 flex flex-row justify-center [counter-reset:list-number]"
        )}
        data-testid="header-navigation">
        {navItems.map((item, index) => (
          <HeaderNavigationItem
            key={index}
            text={item.text}
            location={item.location}
            active={item.active}
            lastItem={index === navItems.length - 1}
            available={item.available}
          />
        ))}
      </ol>
    </nav>
  );
}

HeaderNavigation.propTypes = {
  className: PropTypes.string, // accept className for placement
  navItems: PropTypes.array.isRequired,
};
