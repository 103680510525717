import { useMergeRefs } from "@floating-ui/react";
import PropTypes from "prop-types";
import { forwardRef, useContext } from "react";
import ErrorSummaryContext from "./context";
import Button from "components/Button";

const ErrorSummarySubmit = forwardRef(
  (
    { children, className, formId, design = "in-progress", ...props },
    propRef
  ) => {
    const context = useContext(ErrorSummaryContext);
    const ref = useMergeRefs([
      context.refs.setReference,
      propRef,
      children?.ref,
    ]);

    return (
      <Button
        className={className}
        design={design}
        type="submit"
        formId={formId}
        formMethod="post"
        testID="page-submit"
        ref={ref}
        {...context.getReferenceProps(props)}>
        {children}
      </Button>
    );
  }
);

ErrorSummarySubmit.displayName = "ErrorSummary.Submit";
ErrorSummarySubmit.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  formId: PropTypes.string.isRequired,
  design: PropTypes.oneOf(["tertiary", "in-progress"]), // built-in styles
  onSubmit: PropTypes.func,
};

export default ErrorSummarySubmit;
