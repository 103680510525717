export default function SisNotImportedView() {
  return (
    <>
      <div className="pl-48 mt-16">
        <h1
          className="text-ignite-purple-dark text-[24px]"
          data-testid="sis-not-imported-message">
          Your SIS is not yet imported.
        </h1>
        <h2 className="text-ignite-purple-dark text-[40px] font-bold text-[40px]">
          Import SIS.
        </h2>
        <p className="text-ignite-grey-dark mt-6 text-[16px]">
          Reach out to your Ignite admin to begin this process.
        </p>
      </div>
    </>
  );
}
