import PropTypes from "prop-types";
import classnames from "classnames";
import { Link } from "react-router-dom";

/**
 * Stylized navigation item for the header
 * @param className - any additional classes to add to the component, for ex margins
 * @param location: location that the user is routed to on click
 * @param text: text to display in the anchor navigation item
 * @param active: boolean to determine if the item is active
 * @param lastItem: boolean to determine if the item is the last in the list
 * @param available: boolean to determine if the item is available for navigation
 */

export default function HeaderNavigationItem({
  className,
  location,
  text,
  active,
  lastItem,
  available,
}) {
  let classes;
  if (active) {
    classes = "before:bg-ignite-purple before:text-white before:drop-shadow";
  } else if (available) {
    classes = "before:border-[1px] before:border-ignite-grey";
  } else {
    classes = "before:bg-ignite-grey-light before:text-ignite-grey-dark-alt";
  }

  const Number = () => (
    <span
      className={classnames(
        classes,
        // Use the counter as the number
        "before:[counter-increment:list-number] before:content-[counter(list-number)]",
        // Style the number
        "before:inline-flex before:justify-center before:items-center before:w-6 before:h-6 before:rounded-full before:mr-2 before:text-xs"
      )}
    />
  );

  return (
    <li
      className={classnames(className, "flex group text-black", {
        "after:content-[''] after:block after:w-10 after:h-px after:bg-ignite-grey-light after:mx-4 after:mt-3":
          !lastItem,
        "text-ignite-grey-alt": !active && !available,
      })}
      aria-current={active}>
      {available && !active ? (
        <Link
          className="group-hover:underline focus-visible:underline"
          to={location}>
          <Number />
          {text}
        </Link>
      ) : (
        <>
          <Number />
          {text}
        </>
      )}
    </li>
  );
}

HeaderNavigationItem.propTypes = {
  className: PropTypes.string, // accept className for placement
  location: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  active: PropTypes.bool,
  lastItem: PropTypes.bool,
  available: PropTypes.bool,
};
