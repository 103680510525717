import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";

/**
 * Component for Links within the SchoolCard component.
 *
 * @param {string} className - additional classes to add to the component
 * @param {string} schoolId - The ID of the school for testing.
 * @param {string} url - The URL to link to.
 * @param {string} text - The text to display in the link.
 */
export default function SchoolCardLink({ className, schoolId, url, text }) {
  return (
    <Link
      to={url}
      data-testid={`schoolCardLink-${schoolId}`}
      className={classNames(
        "block h-14	w-64 p-3 text-xl text-center bg-ignite-purple text-white rounded-md font-medium border-2 border-ignite-purple",
        "focus-visible:bg-white focus-visible:text-ignite-purple",
        "hover:bg-white hover:text-ignite-purple",
        className
      )}>
      {text}
    </Link>
  );
}

SchoolCardLink.propTypes = {
  className: PropTypes.string,
  schoolId: PropTypes.string,
  url: PropTypes.string.isRequired,
  text: PropTypes.string,
};
