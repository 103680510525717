import { Button } from "components";
import LandingLayout from "layouts/LandingLayout";
import { useEffect, useState } from "react";
import { Link, useNavigate, useRouteLoaderData } from "react-router-dom";
import pluralize from "pluralize";

export default function OpenSeatsView() {
  const { schools } = useRouteLoaderData("auth");
  const navigate = useNavigate();

  const [school, setSchool] = useState(null);

  useEffect(() => {
    if (schools.length > 1) {
      navigate("/school-selection");
    } else {
      setSchool(schools[0]);
    }
  }, [schools]);

  return (
    school && (
      <LandingLayout
        welcomeMessage="Welcome back"
        title={`You have ${pluralize("open seats", school.num_open_seats, true)}`}
        bodyText={`You've selected ${pluralize("students", school.num_selected_students, true)}. Add ${pluralize("students", school.num_open_seats, true)} to fill your open ${pluralize("seats", school.num_open_seats)}`}
        callToAction={
          <div className="flex space-x-8 items-center">
            <Button
              className="w-64 py-3 px-8 font-medium"
              design="primary"
              onClick={() =>
                navigate(`/schools/${school.uuid}/student-selection`)
              }>
              Add Students to Program
            </Button>
            <Link
              className="text-ignite-purple no-underline hover:underline"
              to={`/schools/${school.uuid}/student-info-completion`}>
              View Submitted and Enrolled Students
            </Link>
          </div>
        }
      />
    )
  );
}
