import classNames from "classnames";
import { SCHOOL_STATUS } from "constants/enums/school_status";
import { PropTypes } from "prop-types";
import SchoolCardLink from "./SchoolCardLink";
import pluralize from "pluralize";

/**
 * Card component for interacting with schools on the School Selection page.
 */
export default function SchoolCard({
  schoolName,
  schoolCity,
  schoolState,
  schoolStatus,
  schoolId,
  schoolOpenSeats,
  className,
  testID = "xSchoolCard",
  ...args
}) {
  const STATUS_UI = {
    [SCHOOL_STATUS.UNIMPORTED]: (
      <div>
        <p className="text-base font-medium">Your SIS is not yet imported.</p>
        <p className="text-ignite-grey-dark">
          Reach out to your Ignite admin to begin this process.
        </p>
      </div>
    ),
    [SCHOOL_STATUS.IMPORTED_RECORDS]: (
      <SchoolCardLink
        schoolId={schoolId}
        url={`/schools/${schoolId}/student-selection`}
        text="Start Student Setup"
      />
    ),
    [SCHOOL_STATUS.SI_INCOMPLETE]: (
      <SchoolCardLink
        schoolId={schoolId}
        url={`/schools/${schoolId}/student-info-completion`}
        text="Complete Student Info"
      />
    ),
    [SCHOOL_STATUS.IMPORTED_NO_RECORDS]: (
      <div>
        <p className="text-base font-medium">No student records imported.</p>
        <p className="text-ignite-grey-dark">
          Adjust the permissions in your SIS to allow export student records to
          Ignite Reading.
        </p>
      </div>
    ),
    [SCHOOL_STATUS.OPEN_SEATS]: (
      <div>
        <p className="py-3 text-base font-medium">
          Add {pluralize("students", schoolOpenSeats, true)} to fill your open
          seats.
        </p>
        <SchoolCardLink
          className="float-right"
          schoolId={schoolId}
          url={`/schools/${schoolId}/student-selection`}
          text="Add Students to Program"
        />
      </div>
    ),
    default: (
      <SchoolCardLink
        schoolId={schoolId}
        url={`/schools/${schoolId}/student-selection`}
        text="View Students"
      />
    ),
  };
  const statusUI = STATUS_UI[schoolStatus] || STATUS_UI.default;

  return (
    <li
      data-testid={testID}
      className={classNames("w-full h-36 flex min-w-[600px] py-14", className)}
      {...args}>
      <div className="w-2/4">
        <h3 className="font-bold text-ignite-purple-dark text-2xl">
          {schoolName}
        </h3>
        <p className="mt-1 text-base font-medium">{`${schoolCity}, ${schoolState}`}</p>
      </div>
      <div className="w-2/4 flex flex-wrap content-center justify-end">
        {statusUI}
      </div>
    </li>
  );
}

SchoolCard.propTypes = {
  schoolName: PropTypes.string.isRequired,
  schoolCity: PropTypes.string.isRequired,
  schoolState: PropTypes.string.isRequired,
  schoolStatus: PropTypes.oneOf([
    "unimported",
    "imported-records",
    "imported-no-records",
    "si-incomplete",
    "ready",
  ]).isRequired,
  schoolId: PropTypes.string.isRequired,
  schoolOpenSeats: PropTypes.number.isRequired,
  className: PropTypes.string,
  testID: PropTypes.string,
};
