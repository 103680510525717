import { useActionData, useParams, useRouteLoaderData } from "react-router-dom";
import { DataGrid, OpenSeats } from "components";
import { useEffect, useMemo, useState } from "react";

export default function StudentSelectionView() {
  const errors = useActionData();
  const { students, studentColumns } = useRouteLoaderData("students");
  const { schools } = useRouteLoaderData("auth");
  const { schoolId } = useParams();
  const school = schools.filter(row => row.uuid === schoolId)[0];

  const getStudentsSelected = students => {
    return students
      .filter(student => student.selected)
      .map(student => student.uuid);
  };
  const studentsInitiallySelected = useMemo(
    () => getStudentsSelected(students),
    [students]
  );

  const [currStudents, updateCurrStudents] = useState(students);
  const [newStudentsSelected, setNewStudentsSelected] = useState([]);

  const getTotalSeats = () =>
    school.school_seats
      .map(seat => seat.num_seats)
      .reduce((sum, numSeats) => sum + numSeats, 0);
  const getSelectedSeats = () =>
    currStudents.filter(row => row.selected).length;

  const [numOpenSeats, setNumOpenSeats] = useState(
    getTotalSeats() - getSelectedSeats() || 0
  );

  useEffect(() => {
    setNumOpenSeats(getTotalSeats() - getSelectedSeats());
    const studentsSelected = getStudentsSelected(currStudents);
    setNewStudentsSelected(
      studentsSelected.filter(
        student => !studentsInitiallySelected.includes(student)
      )
    );
  }, [currStudents]);

  const onCheck = ({ name, checked }) => {
    const selectedStudentIndex = currStudents.findIndex(
      student => student.uuid === name.split("_")[1]
    );
    currStudents[selectedStudentIndex].selected = checked;
    updateCurrStudents([...currStudents]);
  };

  return (
    <div className="flex flex-row justify-between ml-60 mr-20">
      <div className="mt-16">
        <h1 className="text-ignite-purple-dark text-2xl font-semibold">
          Select Students for the Program
        </h1>
        <p className="mt-2 mb-10 text-ignite-grey-dark">
          Select students that you would like to enroll in the current
          programming session.
        </p>
        <div className="w-fit mb-12">
          <DataGrid
            columns={studentColumns}
            rows={currStudents}
            keys={["name", "teacher-name", "grade-level"]}
            multiSelect
            disableSelect={numOpenSeats <= 0}
            allowEdits={false}
            onSelect={e =>
              onCheck({
                name: e.target.name,
                checked: e.target.checked,
              })
            }
            height="600px"
            formId="student-selection-form"
          />
          <input
            aria-hidden={true}
            form="student-selection-form"
            className="invisible absolute bottom-0 w-full"
            name="num-open-seats"
            value={numOpenSeats}
            readOnly
          />
          <input
            aria-hidden={true}
            form="student-selection-form"
            className="invisible absolute bottom-0 w-full"
            name="roster-status"
            value={newStudentsSelected.length > 0 ? "selected" : ""}
            readOnly
          />
        </div>
      </div>
      <div className="flex flex-col items-end mr-10">
        <OpenSeats
          numSelectedSeats={getSelectedSeats()}
          numTotalSeats={getTotalSeats()}
          numOpenSeats={numOpenSeats}
          className="mt-14"
        />
        <DataGrid.ErrorSummary errors={errors || []}>
          <DataGrid.ErrorSummary.Submit
            design="tertiary"
            formId="student-selection-form"
            className="py-3 px-8 mt-16">
            Save & Next
          </DataGrid.ErrorSummary.Submit>
          <DataGrid.ErrorSummary.Content />
        </DataGrid.ErrorSummary>
      </div>
    </div>
  );
}
