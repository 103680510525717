import { Outlet, useLoaderData } from "react-router-dom";
import AuthHeader from "components/AuthHeader";

/**
 * Layout for pages that are auth'd -- header includes a logo and profile button
 **/
export default function AuthLayout() {
  const { profileData } = useLoaderData();
  const { firstName, lastName } = profileData;

  return (
    <div className="w-screen h-screen">
      <AuthHeader
        firstName={firstName}
        lastName={lastName}
      />
      <main className="w-full h-full">
        <Outlet />
      </main>
    </div>
  );
}
