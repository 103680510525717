import { Button } from "components";
import LandingLayout from "layouts/LandingLayout";
import { useEffect, useState } from "react";
import { useNavigate, useRouteLoaderData } from "react-router-dom";

export default function StudentSetupView() {
  const { schools } = useRouteLoaderData("auth");
  const navigate = useNavigate();

  const [school, setSchool] = useState(null);

  useEffect(() => {
    if (schools.length > 1) {
      navigate("/school-selection");
    } else {
      setSchool(schools[0]);
    }
  }, [schools]);

  return (
    school && (
      <LandingLayout
        welcomeMessage={
          <>
            <span
              className="font-bold"
              data-testid="school-name">
              {school.name}
            </span>{" "}
            student import successful.
          </>
        }
        title="Begin Student Setup"
        bodyText={
          "Start by selecting the students in program, and we'll walk you through completing the student information."
        }
        callToAction={
          <Button
            className="w-56 py-3 px-8 font-medium"
            design="primary"
            onClick={() =>
              navigate(`/schools/${school.uuid}/student-selection`)
            }>
            Get Started
          </Button>
        }
      />
    )
  );
}
