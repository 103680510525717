import DataGridInput from "../DataGridInput";
import PropTypes from "prop-types";
import classnames from "classnames";
import { dataGridCell, dataGridColumn } from "components/DataGrid/proptypes";

export default function DataGridRowBodyCell({
  cell,
  cellIndex,
  rowIndex,
  column,
  totalRows,
  totalCols,
  allowEdits,
  disabled,
  uuids,
  onEdit,
}) {
  return (
    <td
      role="gridcell"
      aria-colindex={cellIndex + 2}
      aria-rowindex={rowIndex}
      title={cell.value}
      aria-label={cell.value}
      aria-readonly={!column.editable}
      className={classnames(
        "relative h-full border-ignite-grey-border",
        "before:content-[''] before:absolute before:top-0 before:left-0 before:h-[101%] before:w-2.5",
        {
          "border-b-[.75px]": rowIndex - 1 !== totalRows,
          "border-r-[.75px]": cellIndex !== totalCols,
          "px-4 whitespace-nowrap text-center overflow-hidden text-ellipsis":
            !allowEdits || !column.editable, // read-only cell styling
          "bg-ignite-grey-lightest": allowEdits && !column.editable, // read-only cell styling when allowing edits
          "bg-ignite-pink-light cell-error": cell.error, //error styling
          "opacity-40": disabled, //disabled styling
        }
      )}>
      {/* editable cells */}
      {allowEdits && column.editable ? (
        <DataGridInput
          error={cell.error}
          disabled={disabled}
          dataType={column.dataType}
          options={column.options}
          colHeader={column.colName}
          shouldStartPink={
            column.editable && allowEdits && !cell.error && !column.dependency
          }
          value={cell.value}
          values={cell.values}
          name={`${column.key}_${uuids}`}
          id={`${column.key}_${uuids}`}
          onBlur={onEdit}
        />
      ) : // read only cells
      column.options ? (
        //select read only
        <span>
          {
            column.options.find(opt => opt.value === cell.value?.toLowerCase())
              ?.label
          }
        </span>
      ) : (
        //other cells
        <span>{cell.value}</span>
      )}
    </td>
  );
}

DataGridRowBodyCell.propTypes = {
  cell: dataGridCell,
  cellIndex: PropTypes.number.isRequired,
  rowIndex: PropTypes.number.isRequired,
  column: dataGridColumn,
  totalRows: PropTypes.number.isRequired,
  totalCols: PropTypes.number.isRequired,
  allowEdits: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  uuids: PropTypes.string.isRequired,
  onEdit: PropTypes.func,
};
