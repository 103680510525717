export const ROSTER_NAV_ITEMS = {
  0: {
    page: "school-selection",
    text: "Select School",
    needsSchoolId: false,
    needsSIComplete: false,
  },
  1: {
    page: "student-selection",
    text: "Select Students",
    needsSchoolId: true,
    needsSIComplete: false,
  },
  2: {
    page: "student-info-completion",
    text: "Complete Student Information",
    needsSchoolId: true,
    needsSIComplete: false,
  },
  3: {
    page: "student-information-review",
    text: "Student Information Review",
    needsSchoolId: true,
    needsSIComplete: true,
  },
};
