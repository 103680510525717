import classnames from "classnames";
import SortHeader from "../SortHeader";
import { SORT_TYPE } from "constants/enums/sort";
import PropTypes from "prop-types";
import { dataGridColumn } from "components/DataGrid/proptypes";

export default function DataGridHeaderCell({
  column,
  columnIndex,
  scrolling,
  toggleSort,
  totalCols,
}) {
  return (
    <th
      scope="col"
      aria-label={column.colName}
      aria-sort={column.activeSortDirection}
      aria-rowindex="1"
      aria-colindex={columnIndex + 1}
      className={classnames(
        "sticky top-0 border-b-[.75px] border-ignite-grey-border box-shadow",
        {
          "border-r-[.75px]": columnIndex !== totalCols,
          "bg-ignite-grey-lighter shadow-down z-[3]": columnIndex !== 0,
          "left-0 z-[4] bg-white shadow-right": columnIndex === 0,
          "shadow-right-down": columnIndex === 0 && scrolling,
        }
      )}>
      {column.sortType === SORT_TYPE.DISABLED ? (
        <span
          className="px-10 whitespace-nowrap"
          data-testid={`no-sort-${column.colName.replace(/\s+/g, "-")}`}>
          {column.colName}
        </span>
      ) : (
        <SortHeader
          toggleSort={toggleSort}
          activeSortDirection={column.activeSortDirection}
          sortType={column.sortType}
          field={column.colName}
          sortIndex={columnIndex}
          className={classnames({
            "w-[354px] justify-end": columnIndex === 0,
            "justify-center": columnIndex !== 0,
          })}>
          {columnIndex === 0 ? (
            <span>
              <span className="inline-block text-left w-32 whitespace-nowrap">
                Last Name
              </span>
              <span className="inline-block text-left w-32 whitespace-nowrap">
                First Name
              </span>
            </span>
          ) : (
            <span className="pl-10 whitespace-nowrap">{column.colName}</span>
          )}
        </SortHeader>
      )}
    </th>
  );
}

DataGridHeaderCell.propTypes = {
  column: dataGridColumn,
  columnIndex: PropTypes.number.isRequired,
  scrolling: PropTypes.bool,
  toggleSort: PropTypes.func.isRequired,
  totalCols: PropTypes.number.isRequired,
};
