const getSchoolId = url => {
  const parts = url.split("/");
  const schoolsIndex = parts.indexOf("schools");
  return schoolsIndex !== -1 ? parts[schoolsIndex + 1] : null;
};

export const getRosterNavItems = (pathname, navItems) => {
  const schoolId = getSchoolId(pathname);
  //TODO: calculate this by data state
  const schoolSIComplete = false;

  // eslint-disable-next-line no-unused-vars
  return Object.entries(navItems).map(([_, item]) => {
    return {
      ...item,
      active: pathname.includes(item.page),
      available: item.needsSchoolId
        ? schoolId
          ? item.needsSIComplete
            ? schoolSIComplete
            : true
          : false
        : true,
      location: item.needsSchoolId
        ? `schools/${schoolId}/${item.page}`
        : item.page,
    };
  });
};
