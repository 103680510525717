import PropTypes from "prop-types";
import classnames from "classnames";

/**
 * Component to display the number of open seats available as the user selects students
 * @param className - any additional classes to add to the component, for ex margins
 * @param numSelectedSeats - the number of seats selected by the user
 * @param numTotalSeats - the total number of seats available, set by Ignite Admin in spark
 * @param numOpenSeats - the number of open seats available
 */
export default function OpenSeats({
  className,
  numSelectedSeats,
  numTotalSeats,
  numOpenSeats,
}) {
  return (
    <div
      className={classnames(
        "border-ignite-grey-light-alt border rounded px-4 pt-4 pb-6 w-96 text-center",
        className
      )}>
      <h2 className="text-ignite-purple-dark text-xl font-bold mb-6">
        Fill Your Open Seats
      </h2>
      <div className="bg-ignite-purple text-white py-14">
        <h3>
          You have{" "}
          <span
            id="num-open-seats"
            data-testid="num-open-seats">
            {numOpenSeats}
          </span>{" "}
          open seat{numOpenSeats !== 1 ? "s" : ""}.
        </h3>
        <p className="text-7xl font-bold leading-tight">
          <span
            id="num-selected-seats"
            data-testid="num-selected-seats">
            {numSelectedSeats}
          </span>
          /<span data-testid="num-total-seats">{numTotalSeats}</span>
        </p>
      </div>
    </div>
  );
}

OpenSeats.propTypes = {
  className: PropTypes.string, // accept className for placement
  numSelectedSeats: PropTypes.number.isRequired,
  numTotalSeats: PropTypes.number.isRequired,
  numOpenSeats: PropTypes.number.isRequired,
};
