import PropTypes from "prop-types";
import classnames from "classnames";
import { forwardRef } from "react";

/**
 * Reusable component for Buttons with styling. NOTE buttons are not links, navigation should use the Anchor component.
 *
 * @param {string} ariaLabel - accessible label for screen readers (necessary if no text is present)
 * @param {string} className - any additional classes to add to the base component, for ex margins
 * @param {string} [design = 'primary'] - built-in styles for the button
 * @param {boolean} [disabled = false] - boolean to disable the button
 * @param {func} onClick - function to run on button click
 * @param {string} [testID = 'xButton'] - test id for testing
 * @param {string} text - text to display on the button
 * @param {string} [type = 'button'] - type of button (e.x. button, submit)
 */
const Button = forwardRef(
  (
    {
      ariaLabel,
      className,
      design = "primary",
      disabled = false,
      onClick,
      testID = "xButton",
      type = "button",
      formId,
      formMethod,
      children,
      ...args
    },
    ref
  ) => {
    return (
      // TODO: replace with design from ui-kit
      <button
        ref={ref}
        className={classnames(
          design,
          className,
          "min-h-6 min-w-6",
          `ignite-button-${design}`
        )}
        onClick={onClick}
        type={type}
        form={formId}
        formMethod={formMethod}
        data-testid={testID}
        disabled={disabled}
        aria-label={ariaLabel}
        {...args}>
        {children}
      </button>
    );
  }
);

Button.displayName = "Button";
Button.propTypes = {
  ariaLabel: PropTypes.string,
  className: PropTypes.string, // accept className for placement
  design: PropTypes.oneOf([
    "primary",
    "secondary",
    "tertiary",
    "link",
    "icon",
    "in-progress",
  ]), // built-in styles
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  testID: PropTypes.string,
  type: PropTypes.oneOf(["button", "submit"]), // only allowable types, button to ignore form submission
  formId: PropTypes.string,
  formMethod: PropTypes.string,
  children: PropTypes.node,
};

export default Button;
