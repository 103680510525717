import { SchoolCard } from "components";
import { useRouteLoaderData } from "react-router-dom";

export default function SchoolSelectionView() {
  const { schools } = useRouteLoaderData("auth");

  return (
    <div className="mx-40">
      <h1 className="mt-9 text-[42px] text-ignite-purple-dark font-bold">
        Select a school to get started.
      </h1>
      <h2 className="my-3.5 text-2xl">Set up your Ignite Reading program.</h2>
      <ul className="mt-12">
        {schools.map(school => (
          <SchoolCard
            key={school.uuid}
            schoolName={school.name}
            schoolCity={school.city}
            schoolState={school.state}
            schoolStatus={school.status}
            schoolId={school.uuid}
            schoolOpenSeats={school.num_open_seats}
            testID={`${school.name}-card`}
          />
        ))}
      </ul>
    </div>
  );
}
