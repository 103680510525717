import PropTypes from "prop-types";

/**
 * Simple layout for landing pages that generally look the same. Used on each view.
 *
 * @param {string || node} [welcomeMessage = "welcome back"] - welcome message to display
 * @param {string} title - title of the landing page
 * @param {string} bodyText - body text of the landing page
 * @param {node} callToAction - call to action buttons
 */
export default function LandingLayout({
  welcomeMessage = "Welcome back",
  title,
  bodyText,
  callToAction,
}) {
  return (
    <div className="ml-60 mr-20 mt-40">
      <h1 className="text-ignite-purple-dark text-2xl">{welcomeMessage}</h1>
      <h2 className="text-ignite-purple-dark text-3xl font-bold mt-5">
        {title}
      </h2>
      <p className="text-ignite-grey-dark text-lg mt-10 w-[500px]">
        {bodyText}
      </p>
      <div className="mt-20">{callToAction}</div>
    </div>
  );
}

LandingLayout.propTypes = {
  welcomeMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  bodyText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  callToAction: PropTypes.node.isRequired,
};
